@import 'commonStyles.scss';
@import 'variables.scss';
@import 'designSystem.scss';

.heading-container {
    position: sticky;
    top: 0px;
    z-index: 1000;
    background-color: $surfaces-backgroundcolor;
}

.heading {
    padding: 8px;
    border-radius: 12px;
    border: 1px solid $surfaces-surface2;
    background: $white;

    .heading-left {
        gap: 12px;
    }

    .title-editor {
        padding: 4px 12px;
        gap: 4px;
        border-radius: 10px;
        background: $white;
        color: $text-color;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.18px;
        width: 100%;
        font-family: "Inter";
        display: inline-flex;

        &.edit {
            background: $surfaces-backgroundcolor;
            &:hover {
                background: $surfaces-surface1;
                opacity: 0.4;
            }
        }
    }

    .title-editor-input-container {
        width: 100%;

        input {
            border: none;
            outline: none; 
            background: transparent;
            width: 100%;
        }
    }
}

/* Target the edit button with high specificity */
.heading-container .heading button[class*="edit-button"][class*="shadow"] {
    color: $brand-green100;
}

