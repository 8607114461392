@import 'commonStyles.scss';
@import 'variables.scss';
@import 'designSystem.scss';

/* Define the pulse animation */
@keyframes pulse-border {
    0% {
        border-color: $brand-green80;
        box-shadow: 0 0 0 0 rgba(0, 184, 121, 0.4);
    }
    50% {
        border-color: #00b879;
        box-shadow: 0 0 0 8px rgba(0, 184, 121, 0.3); /* Increased glow size for better visibility */
    }
    100% {
        border-color: $brand-green80;
        box-shadow: 0 0 0 0 rgba(0, 184, 121, 0.4);
    }
}

/* Define the border trace animation */
@keyframes border-trace {
    0% {
        border-color: rgba(0, 184, 121, 0.3);
    }
    50% {
        border-color: #00b879;
    }
    100% {
        border-color: rgba(0, 184, 121, 0.3);
    }
}

/* Define the outline trace animation */
@keyframes outline-trace {
    0% {
        background-position: 0% 0%;
    }
    100% {
        background-position: 200% 0%;
    }
}

/* Define a smoother appear/disappear animation for boxes */
@keyframes fade-in {
    0% { 
        opacity: 0;
        transform: translateY(8px);
    }
    100% { 
        opacity: 1;
        transform: translateY(0);
    }
}

/* Define a staggered fade-in for section items */
@keyframes staggered-fade-in {
    0% { 
        opacity: 0;
        transform: translateY(8px);
    }
    70% {
        opacity: 0.8;
        transform: translateY(2px);
    }
    100% { 
        opacity: 1;
        transform: translateY(0);
    }
}

/* Define a glow pulse animation */
@keyframes glow-pulse {
    0% {
        box-shadow: 0 0 8px rgba(0, 184, 121, 0.3);
    }
    50% {
        box-shadow: 0 0 20px rgba(0, 184, 121, 0.6);
    }
    100% {
        box-shadow: 0 0 8px rgba(0, 184, 121, 0.3);
    }
}

/* Define a checkmark appear animation */
@keyframes check-appear {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    50% {
        opacity: 1;
        transform: scale(1.2);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

/* Define content appear animation */
@keyframes content-appear {
    0% {
        opacity: 0.7;
        transform: scale(0.98);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.message-box {
    width: 290px;
    
    /* Add transition for smooth changes */
    .message-box-content-assistant, 
    .generation-box, 
    .message-box-content-assistant-message,
    .assistant-logs,
    .generation-plan,
    .final-content-container {
        transition: all 0.45s cubic-bezier(0.25, 1, 0.5, 1);
        will-change: transform, opacity;
        transform: translateZ(0); /* Force GPU acceleration */
        backface-visibility: hidden;
    }
    
    /* Final content container styles */
    .final-content-container {
        display: flex;
        flex-direction: column;
        gap: 6px; /* Reduced gap between elements */
        min-height: 0; /* Allow container to collapse */
        transition: min-height 0.5s ease;
    }
    
    /* Create container transitioning */
    .assistant-logs + .final-content-container {
        margin-top: 6px;
    }
    
    .final-content-container .generation-plan {
        margin-bottom: 6px;
    }
    
    /* Special animation classes */
    .animate-item {
        opacity: 0;
        transform: translateY(15px);
        animation: staggered-fade-in 0.45s forwards cubic-bezier(0.25, 1, 0.5, 1);
        will-change: transform, opacity;
        transform: translateZ(0); /* Force GPU acceleration */
        backface-visibility: hidden;
    }
    
    .animate-icon {
        transition: transform 0.25s cubic-bezier(0.34, 1.56, 0.64, 1);
        will-change: transform;
        transform: translateZ(0); /* Force GPU acceleration */
        backface-visibility: hidden;
        
        &:hover {
            transform: scale(1.1);
        }
        
        svg:not(.loader) {
            animation: check-appear 0.35s cubic-bezier(0.34, 1.56, 0.64, 1);
        }
    }
    
    /* Add transitions for items within boxes */
    .assistant-logs-item,
    .generation-plan-item {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 0.35s ease, transform 0.35s ease;
        will-change: transform, opacity;
        
        &:nth-child(2) { animation-delay: 0.05s; }
        &:nth-child(3) { animation-delay: 0.1s; }
        &:nth-child(4) { animation-delay: 0.15s; }
        &:nth-child(5) { animation-delay: 0.2s; }
        &:nth-child(6) { animation-delay: 0.25s; }
    }
    
    /* Add transition for icon changes */
    .assistant-logs-item-icon,
    .generation-plan-item-icon {
        transition: all 0.25s cubic-bezier(0.34, 1.56, 0.64, 1);
    }
    
    /* Add smooth transition for text coloring */
    .generation-plan-item-title,
    .assistant-logs-item-message {
        transition: color 0.25s ease;
    }

    .title {
        color: $text-textcolor;
    }

    .markdown-text {
        font-size: 14px;
    
        p {
            margin: 0;
        }
    }
    
    .heading-with-border-container {
        border-bottom: 1px solid  $surfaces-surface1;
        padding-bottom: 12px;
    }


    .sources-container {
        padding: 8px 12px;
        gap: 10px;
        border-radius: 20px;
        border: none;
        background: #25292e;
        position: relative;
        height: auto;
        opacity: 0;
        transform: translateY(0);
        animation: fade-in 0.5s forwards ease-out;
        animation-delay: 0.1s;
        margin-top: 8px;
        transition: height 0.3s ease-out, opacity 0.3s ease-out;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        max-width: fit-content;

        .sources-title {
            color: #fff;
            font-weight: 500;
            font-size: 14px;
            margin-right: 4px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            
            .sources-title-icon {
                display: none;
            }
        }
        
        .sources-content {
            display: flex;
            align-items: center;
            gap: 4px;
        }
    }

    .sources-refSidePanel {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: none;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        cursor: pointer;
        transition: all 0.2s ease;
        flex-shrink: 0;
        
        svg, img {
            width: 16px;
            height: 16px;
            color: #25292e;
        }
        
        .sources-refSidePanel-title {
            display: none;
        }

        &:hover {
            transform: scale(1.1);
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        }
    }

    .generation-box {
        padding: 8px;
        gap: 12px;
        border-radius: 12px;
        border: 1px solid $surfaces-surface2;
        background: $white;
        color: $text-textcolor;

        &.loading {
            border: 1px solid $brand-green80;
            position: relative;
            animation: pulse-border 1.5s infinite ease-in-out;
        }
    }

    .assistant-logs {
        animation: fade-in 0.35s ease-out;
        transform-origin: top center;
        will-change: transform, opacity;
        opacity: 1;
        background: $white;
        border-radius: 12px;
        gap: 8px;
        
        .assistant-logs-content {
            padding: 8px;
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
        
        .assistant-logs-item {
            display: flex;
            align-items: center;
            gap: 6px;
            min-height: 24px;
            justify-content: space-between;
            
            .assistant-logs-item-message {
                color: $text-secondarytextcolor;
                font-family: Inter;
                font-size: 13px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: -0.14px;
                flex: 1;
                
                &.finished {
                    color: #00B879;
                }
            }
            
            .assistant-logs-item-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 24px;
                min-height: 24px;
                margin-left: auto;
                svg {
                    width: 16px;
                    height: 16px;
                    color: #00B879;
                }
            }
        }
        
        &.loading {
            position: relative;
            animation: pulse-border 1.5s infinite ease-in-out;
        }
    }

    .generation-plan {
        animation: fade-in 0.4s ease-out;
        animation-fill-mode: both;
        transform-origin: top center;
        will-change: transform, opacity;
        opacity: 1;
        
        .generation-plan-content {
            padding: 8px;
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
        
        .generation-plan-item {
            display: flex;
            align-items: center;
            min-height: 24px;
            justify-content: space-between;
            
            .generation-plan-item-title {
                color: $text-secondarytextcolor;
                flex: 1;

                &.finished {
                    color: #00B879;
                }
            }

            .generation-plan-item-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 24px;
                min-height: 24px;
                margin-left: auto;
                svg {
                    width: 16px;
                    height: 16px;
                    color: #00B879;
                }
            }
        }
        
        &.loading {
            border: 1px solid $brand-green80;
            position: relative;
            animation: pulse-border 1.5s infinite ease-in-out;
        }
    }

    .message-box-content-assistant {
        border-radius: 16px;
        background: $white;
        padding: 3px;
        gap: 3px;
        border: none;
        
        &.loading {
            border-radius: 16px;
            background: $white;
            border: none;
        }
    }

    .message-box-content-user-message {
        padding: 10px;
        gap: 10px;
        border-radius: 12px;
        border: 1px solid $surfaces-surface2;
        background: $white;
    }   

    .message-box-content-assistant-message {
        padding: 10px;
        gap: 8px;
        border-radius: 12px;
        border: 1px solid $surfaces-surface2;
        background: $white;
        position: relative;
        transition: all 0.45s cubic-bezier(0.25, 1, 0.5, 1);
        will-change: transform, opacity;
        margin-bottom: 0;

        .message-content-wrapper {
            position: relative;
            width: 100%;
            padding-bottom: 6px; /* Space for the show more button */
        }

        .minimized-message {
            // height: 304px;
            max-height: 304px;
            overflow-y: hidden;
            transition: max-height 0.45s cubic-bezier(0.25, 1, 0.5, 1);
            will-change: max-height;
        }

        .citations-container {
            margin-top: 8px; /* Reduced spacing to save space */
            border-top: 1px solid rgba(230, 230, 230, 0.8); /* Subtle divider */
            padding-top: 6px; /* Reduced padding to save space */
            
            .citations-toggle {
                color: #025B62; /* Teal color for citations */
                font-size: 13px; /* Smaller font size */
                font-weight: 500;
                cursor: pointer;
                display: inline-flex;
                align-items: center;
                gap: 6px;
                padding: 2px 6px; /* Minimal padding */
                border-radius: 8px;
                transition: background-color 0.2s ease;
                
                &:hover {
                    background-color: rgba(2, 91, 98, 0.07); /* Subtle hover effect */
                }
            }
            
            .citations-list {
                margin-top: 6px; /* Reduced spacing */
                display: flex;
                flex-direction: column;
                gap: 4px; /* Tighter spacing between citations */
                max-height: 0; /* Hidden by default */
                overflow: hidden;
                transition: max-height 0.3s ease;
                
                &.expanded {
                    max-height: 300px; /* Expands when toggled */
                }
            }
        }
        
        .insert-action-button {
            padding: 8px 0;
            gap: 12px;
            position: relative;
            height: 46px;
            display: flex;
            align-items: center;
            opacity: 0;
            transform: translateY(0);
            animation: fade-in 0.5s forwards ease-out;
            animation-delay: 0.15s;
            margin-top: 4px;
            transition: height 0.3s ease-out, opacity 0.3s ease-out;
            
            button {
                width: 100%;
                background: #00B879; /* Brand green */
                border-radius: 6px;
                font-size: 13px; /* Compact text */
                padding: 8px 16px; /* Standard padding */
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
                transition: all 0.2s ease;
                
                &:hover {
                    background: #009e68; /* Darker shade on hover */
                    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12);
                    transform: translateY(-1px); /* Subtle lift effect */
                }
                
                .caption-2-bold {
                    font-weight: 700;
                    color: white;
                }
            }
        }

        &.loading {
            background: $white;
            border: 1px solid $brand-green80;
            position: relative;
            animation: pulse-border 1.5s infinite ease-in-out;
        }
        
        &.content-ready {
            animation: content-appear 0.6s forwards cubic-bezier(0.2, 1, 0.3, 1);
            border: 1px solid $surfaces-surface2;
            margin-bottom: 3px;
        }
        
        &.with-references {
            margin-bottom: 6px;
        }
    }
    
    .citation-link {
        display: flex;
        align-items: center;
        gap: 6px; /* Reduced spacing */
        padding: 4px 6px; /* Compact padding */
        border-radius: 6px;
        font-size: 12px; /* Smaller font size */
        color: $text-textcolor;
        transition: background-color 0.2s ease;
        text-decoration: none;
        cursor: pointer;
        
        .citation-number {
            background: rgba(2, 91, 98, 0.1); /* Subtle teal background */
            color: #025B62;
            font-size: 11px; /* Smaller font size */
            font-weight: 500;
            min-width: 16px; /* Fixed width for number bubble */
            height: 16px;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
        }
        
        .citation-title {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            flex: 1;
            font-weight: 400;
        }
        
        &:hover {
            background-color: rgba(0, 0, 0, 0.04); /* Subtle hover effect */
        }
    }

    .insert-prd-button {
        padding: 8px;
        gap: 12px;
        position: relative;
        height: auto;
        opacity: 0;
        transform: translateY(0);
        animation: fade-in 0.5s forwards ease-out;
        animation-delay: 0.15s;
        margin-top: 4px;
        transition: height 0.3s ease-out, opacity 0.3s ease-out;
        
        button {
            width: 100%;
            background: #00B879;
        }
    }

    .expand-view-button {
        align-self: flex-end;
    }

    .shadow-gradient {
        position: relative;
        
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 100px; /* Increased height for smoother fade */
          background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 50%, white 100%); /* Enhanced gradient with 3 stops */
          pointer-events: none;
        }
    }

    .show-more-button {
        position: absolute;
        bottom: 10px; /* Position in the middle of the fading gradient */
        left: 50%;
        transform: translateX(-50%);
        background-color: white;
        color: #00B879; /* Brand green color */
        font-size: 13px; /* Reduced for compactness */
        font-weight: 500;
        padding: 3px 14px; /* Compact padding */
        border-radius: 16px;
        cursor: pointer;
        z-index: 2; /* Ensure it appears above the gradient */
        border: none;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
        transition: all 0.2s ease;
        margin-top: 0;
        margin-bottom: 0;
        
        &:hover {
            color: #00975f; /* Darker on hover */
            transform: translateX(-50%) translateY(-2px); /* Subtle lift effect */
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Enhanced shadow on hover */
        }
    }

    .mockup-expand-view-button {
        position: absolute;
        bottom: 12px;
        right: 12px;
    }
}

.full-message-content {
    width: 100%;
    height: 100%;
}

.citation-panel {
    width: 500px !important;
    max-width: 80vw;
    border-radius: 12px 0 0 12px;
    box-shadow: -3px 0 15px rgba(0, 0, 0, 0.08);
}

.mockup-iframe-container {
    flex-grow: 1;
    
    iframe {
        flex: 1;
        border: none;
        width: 100%;
        height: 100%;

    }

    &.chatView {
        cursor: zoom-in;

        iframe {
            pointer-events: none;
        }
    }
}