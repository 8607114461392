@import 'commonStyles.scss';
@import 'variables.scss';
@import 'designSystem.scss';

.requirement-item-container {
    // Add padding if needed
}

.gap-1 {
    gap: 4px;
}

.gap-5 {
    gap: 20px;
}

// Custom empty view styles
.empty-view {
    height: auto;
    padding: 32px 12px;
    gap: 16px;
    background: $surfaces-backgroundcolor;

    .empty-view-content {
        max-width: 350px;
        gap: 16px;
        padding: 16px 0;

        .description {
            color: $text-secondarytextcolor;
            text-align: center;
        }
    }
}
