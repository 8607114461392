@import 'commonStyles.scss';
@import 'variables.scss';
@import 'designSystem.scss';

.resources-wrapper-container {
    position: relative;
}

// Custom empty view styles
.empty-view {
    height: auto;
    padding: 32px 12px;
    gap: 16px;
    background: $surfaces-backgroundcolor;

    .empty-view-content {
        max-width: 350px;
        gap: 16px;
        padding: 16px 0;

        .description {
            color: $text-secondarytextcolor;
            text-align: center;
        }
    }
}

.resources-container {
    .section-title {
        color: $text-color;
    }

    .section-item-container {
        padding: 16px;
        border-radius: 16px;
        border: 1px solid $surface2;
        background: $white;
    }

    .section-item-title {
        color: $text-color;
        font-family: 'Inter';
        font-size: 15px;    
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.09px;
    }

    .section-item-description {
        color: $text-secondarytextcolor;
    }
}

.add-resource-container {
    position: absolute;
    top: 57px;
    left: 0;
    right: 0;
    z-index: 1;
    border-bottom: 1px solid $surface1;
    background: $white;
    box-shadow: $shadow-big;
    padding: 20px;
    gap: 24px;

    .add-resource-container-title {
        color: $text-textcolor;
        font-size: 15px;
        font-weight: 400;
        line-height: 20px; /* 133.333% */
        letter-spacing: -0.3px;
    }
}

.add-resource-file-container {
   padding: 20px;
   text-align: center;
}

