@import 'commonStyles.scss';
@import 'variables.scss';
@import 'designSystem.scss';

.initiative-sidePanel {
    height: calc(100vh - 16px);
    padding: 8px;
    overflow-y: hidden;
    margin: 8px;
    border-radius: 12px;
    border: 1px solid $surfaces-surface2;
    background: $white;
    font-family: "Inter";
    display: flex;
    flex-direction: column;
    width: 48px;
    transition: width 0.25s ease-in-out;
    
    &.animating {
        will-change: width;
    }

    &.expanded {
        width: 288px;
    }

    .initiative-sidePanel-content-header {
        padding: 0 4px;
        flex-shrink: 0;
        transition: all 0.2s ease-in-out;
        
        .header-left {
            gap: 8px;
            transition: all 0.2s ease-in-out;
            
            :global(.icon-button-component) {
                svg {
                    color: $brand-green100;
                }
            }
        }
    }

    .back-button {
        svg {
            color: $brand-green100 !important;
        }
    }

    .title {
        color: $text-textcolor;
        font-size: 15px;
        font-weight: 400;
        line-height: 20px; /* 125% */
        letter-spacing: -0.32px;
        opacity: 1;
        transition: opacity 0.2s ease-in-out;
        white-space: nowrap;
    }

    .navigation {
        padding: 12px 0px;
        gap: 12px;
        overflow-y: auto;
        flex-grow: 1;
        transition: all 0.2s ease-in-out;

        .navigation-main {
            padding-bottom: 8px;
            gap: 8px;
            border-bottom: 1px solid $surfaces-surface2;
            min-height: min-content;
            width: 100%;
            transition: all 0.2s ease-in-out;

            .section-container {
                .indented-items {
                    margin-left: 20px;
                    margin-top: 4px;
                    gap: 4px;
                }

                .requirement-draggable {
                    width: 100%;
                    
                    &.dragging {
                        background-color: $brand-green50;
                        border-radius: 8px;
                        opacity: 0.8;
                    }
                    
                    &.active {
                        .requirement-item {
                            background-color: $brand-green40;
                            border-radius: 8px;
                            
                            .navigation-item-title {
                                color: $brand-primarycolor;
                                font-weight: 500;
                            }
                        }
                    }
                }

                .requirement-item {
                    padding: 4px 12px;
                    font-size: 12px;
                    opacity: 0.9;
                    width: 100%;
                    border-radius: 8px;
                    transition: background-color 0.2s ease;

                    &.active {
                        background-color: $brand-green40;
                        
                        .navigation-item-title {
                            color: $brand-primarycolor;
                            font-weight: 500;
                        }
                    }
                    
                    &.draggable {
                        cursor: grab;
                        
                        &:hover {
                            background-color: $brand-green50;
                        }
                    }

                    &:hover {
                        opacity: 1;
                        background-color: $surfaces-surface1;
                    }
                    
                    .truncate {
                        max-width: 190px;
                        display: block;
                    }
                }
            }

            .navigation-item {
                padding: 8px 12px;
                gap: 8px;
                border-radius: 12px;
                color: $text-textcolor;
                cursor: pointer;
                transition: all 0.2s ease-in-out;

                &:hover {
                    background: $surfaces-surface1;
                }

                &.active {
                    background: $brand-green40;
                    color: $brand-primarycolor;
                    .navigation-item-title {
                        color: $brand-primarycolor;
                    }
                }

                &.new {
                    opacity: 0.5;
                }
                
                .expand-icon {
                    color: $icon-iconcolor;
                    width: 16px;
                    height: 16px;
                    transition: transform 0.2s ease-in-out;
                }
            }

            .navigation-item-icon {
                width: 20px;
                height: 20px;
                transition: margin 0.2s ease-in-out;
            }

            .navigation-item-title {
                overflow: hidden;
                color: $text-textcolor;
                text-overflow: ellipsis;
                transition: opacity 0.15s ease-in-out, max-width 0.2s ease-in-out;
                
                &.loading {
                    opacity: 0.5;
                }
                
                &.truncate {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 100%;
                }
            }
        }
    }

    .initiative-sidePanel-score {
        border-radius: 16px;
        border: 1px solid $surfaces-surface2;
        background: $surfaces-backgroundcolor;
        width: 100%;
        padding: 4px;
        gap: 10px;


        .initiative-sidePanel-score-content {
            border-radius: 14px;
            border: 1px solid $surfaces-surface2;
            background: $white;
            box-shadow: $shadow-akhooekhto;
            padding: 4px 8px 4px 4px;
        }
    }

    .scoreBox-container {
        gap: 8px;

        .score {
            padding: 8px 7px 8px 8px;
            border-radius: 10px;
            background: $brand-green80;
            color: $white;
            font-family: "Inter";
            font-size: 18px;
            font-weight: 800;
            line-height: 24px;
            letter-spacing: -0.18px;
        }

        .score-text-title {
            color: $text-secondarytextcolor;
        }

        .score-text-description {
            color: $text-primarytextcolor;
        }

        .score-icon {
            color: $icon-iconcolor;
            width: 20px;
            height: 20px;
        }
    }

    .add-new-section {
        width: 100%;
        &.expanded {
            justify-content: flex-start;
        }
    }

    .collapse-button-container {
        padding: 8px 4px 4px;
        border-top: 1px solid $surfaces-surface2;
        margin-top: 8px;
        width: 100%;
        display: flex;
        justify-content: center;
        
        .collapse-button {
            border-radius: 8px;
            transition: all 0.2s ease-in-out;
            display: flex;
            align-items: center;
            justify-content: center;
            
            &.collapsed {
                width: auto !important;
                min-width: unset;
                padding: 8px;
            }
            
            svg {
                color: $brand-green100;
                margin-right: 8px;
            }
            
            &.collapsed svg {
                margin-right: 0;
            }
            
            .collapse-text {
                color: $text-textcolor;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
            }
        }
    }
}

// Tooltip styles
.requirement-tooltip {
    max-width: 300px;
    padding: 12px !important;
    background-color: rgba(0, 0, 0, 0.9) !important;
    
    .tooltip-content {
        .tooltip-title {
            color: $white;
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 8px;
        }
        
        .tooltip-description {
            color: $white;
            font-size: 12px;
            line-height: 1.4;
            
            :global {
                p {
                    margin: 0;
                    color: rgba(255, 255, 255, 0.9);
                }
                
                ul, ol {
                    margin: 4px 0;
                    padding-left: 20px;
                }
                
                code {
                    background: rgba(255, 255, 255, 0.1);
                    padding: 2px 4px;
                    border-radius: 4px;
                }
                
                a {
                    color: $brand-green40;
                    text-decoration: none;
                    
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.initiative-sidePanel-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}