@import 'commonStyles.scss';
@import 'variables.scss';
@import 'designSystem.scss';

.main-content-container {
    padding: 12px;
    background: $surfaces-backgroundcolor;
    border-radius: 16px;
    border: 1px solid $surfaces-surface2;

    .main-content {
        border-radius: 12px;
        border: 1px solid $surfaces-surface2;
        background:$white;
        padding: 16px;
        gap: 24px;
    }

    :global(.title-editor-input) {
        :global(.title-editor-input-input) {
            width: 100%; 
        }
    }
}